<template>
  <v-row
    v-if="currentApartment"
    dense
    :class="[
      currentForm.styles.align == 'center'
        ? 'justify-center'
        : currentForm.styles.align == 'left'
        ? 'justify-start'
        : 'justify-end',
    ]"
  >
    <!-- Info -->
    <v-col
      :cols="width.cols"
      :sm="width.sm"
      :md="width.md"
      :lg="width.lg"
      :xl="width.xl"
      :style="{
        fontFamily: font,
        backgroundColor: backgroundColor,
        color: '#ffffff',
      }"
      class="hero-header"
    >
      <h1 class="ml-2 hero-main-text">
        <span v-if="currentApartment.floorPlan">{{ currentApartment.floorPlan }} </span>
        <span v-if="currentApartment.floorPlan">|</span> {{ currentApartment.area }} m²
        <span v-if="currentApartment.estimatedRent">| </span>
        <span v-if="currentApartment.estimatedRent"
          >{{ formatCardPrice(currentApartment.estimatedRent) }}/kk</span
        >
      </h1>

      <p class="ml-2 hero-small-text">
        <strong>{{ currentApartment.address }}</strong
        >, {{ currentApartment.zipCode }} {{ currentApartment.city }},
        {{ currentApartment.houseType
        }}<span v-if="currentApartment.houseType && currentApartment.condominium.buildYear">, </span
        >{{ currentApartment.condominium.buildYear }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("form", ["currentApartment", "currentForm"]),

    width() {
      const w = this.$store.state.form.currentForm.styles.width.split(" ");
      return {
        cols: w[0],
        sm: w[1],
        md: w[2],
        lg: w[3],
        xl: w[4],
      };
    },

    backgroundColor() {
      return this.$store.state.form.currentForm.styles.colors.fieldColor;
    },

    font() {
      return this.$store.state.form.currentForm.styles.fontFamily;
    },
  },

  watch: {
    windowSize() {
      if (this.windowSize < 900) {
        this.imgContainerHeight = 300;
      } else {
        this.imgContainerHeight = 500;
      }
    },
  },
};
</script>

<style scoped src="../../assets/css/apartmentStyles.css"></style>
